// 全局-回到首页悬浮窗
<template>
  <GlobalImage
    ref="GlobalImageRef"
    v-show="!['indexModule', 'videoModule', 'shareVideo', 'heartBeat', 'nhcj', 'imMessage'].includes(router.currentRoute.value.name) && !isNotNeedInit() && !isNotLocationInit()"
    @click="goBack"
    :class="{ is_hide: isHide }"
    class="back_index_pic"
    :src="getImageUrl('public', 'GlobalBackToIndex_icon_01.png')"
  ></GlobalImage>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useGlobalThrottle } from '@/hooks/useVant'
import { getImageUrl, backToHome } from '@/utils'
import { onClickOutside } from '@vueuse/core'
import { isNotNeedInit, isNotLocationInit } from '@/hooks/useNotNeedInit'

const GlobalImageRef = ref(null)
const router = useRouter()
const isHide = ref(true)
let indexTimer = null

onClickOutside(GlobalImageRef, () => {
  isHide.value = true
  clearTimeout(indexTimer)
})

watch(
  () => router.currentRoute.value.name,
  () => {
    isHide.value = true
    clearTimeout(indexTimer)
  }
)

const goBack = useGlobalThrottle(() => {
  if (isHide.value) {
    isHide.value = false
    indexTimer = setTimeout(() => {
      if (!isHide.value) {
        isHide.value = true
      }
    }, 3000)
    return
  }
  if (!isHide.value) {
    backToHome()
  }
}, 500)
</script>

<style lang="scss" scoped>
.back_index_pic {
  position: fixed;
  bottom: 120px;
  right: -0px;
  z-index: 999;
  width: 90px;
  height: 72px;
  transition: all 0.3s;
  &.is_hide {
    right: -60px;
  }
}
</style>
