// 全局-回到成长中心悬浮窗
<template>
  <GlobalImage
    ref="GlobalGrowthRef"
    v-if="showGrowthIcon"
    @click="goBack"
    :class="{ is_hide: isHide }"
    class="back_index_pic"
    :src="getImageUrl('growth', 'growth_home_icon.png')"
  ></GlobalImage>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useGlobalThrottle } from '../hooks/useVant'
import { getImageUrl, push } from '../utils'
import { onClickOutside } from '@vueuse/core'

const GlobalGrowthRef = ref(null)
const router = useRouter()
const isHide = ref(true)
let indexTimer = null
//myModuleHome
const routerNameList = [
  'technicianDetail',
  'trendsDetail',
  'refundDetail',
  'orderDetail',
  'growthCenter',
  'myGrowth',
  'growthDetail',
  'levelDescription',
  'equityDetails',
]
const showGrowthIcon = ref(false)

onClickOutside(GlobalGrowthRef, () => {
  isHide.value = true
  clearTimeout(indexTimer)
})

watch(
  () => router.currentRoute.value,
  (n, o) => {
    if (routerNameList.includes(o.name) && n.query.isShowGrowthBtn === '1') {
      showGrowthIcon.value = true
    } else {
      showGrowthIcon.value = false
    }
    isHide.value = true
    clearTimeout(indexTimer)
  }
)

const goBack = useGlobalThrottle(() => {
  if (isHide.value) {
    isHide.value = false
    indexTimer = setTimeout(() => {
      if (!isHide.value) {
        isHide.value = true
      }
    }, 3000)
    return
  }
  if (!isHide.value) {
    push('/myModule/growthCenter/index')
  }
}, 500)
</script>

<style lang="scss" scoped>
.back_index_pic {
  position: fixed;
  bottom: 80px;
  right: -0px;
  z-index: 999;
  width: 95px;
  height: 45px;
  transition: all 0.3s;
  &.is_hide {
    right: -64px;
  }
}
</style>
